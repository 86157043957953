import { default as indexWqO2tKHSysMeta } from "/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/callback/index.vue?macro=true";
import { default as indexAWDJoBIA5wMeta } from "/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/editor/index.vue?macro=true";
import { default as indexLo78Y8YVDqMeta } from "/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/forbidden/index.vue?macro=true";
import { default as indexA06le4Q0SLMeta } from "/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/index.vue?macro=true";
import { default as indexlSYE91ATIfMeta } from "/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/logout/index.vue?macro=true";
export default [
  {
    name: indexWqO2tKHSysMeta?.name ?? "callback",
    path: indexWqO2tKHSysMeta?.path ?? "/callback",
    meta: indexWqO2tKHSysMeta || {},
    alias: indexWqO2tKHSysMeta?.alias || [],
    redirect: indexWqO2tKHSysMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/callback/index.vue").then(m => m.default || m)
  },
  {
    name: indexAWDJoBIA5wMeta?.name ?? "editor",
    path: indexAWDJoBIA5wMeta?.path ?? "/editor",
    meta: indexAWDJoBIA5wMeta || {},
    alias: indexAWDJoBIA5wMeta?.alias || [],
    redirect: indexAWDJoBIA5wMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/editor/index.vue").then(m => m.default || m)
  },
  {
    name: indexLo78Y8YVDqMeta?.name ?? "forbidden",
    path: indexLo78Y8YVDqMeta?.path ?? "/forbidden",
    meta: indexLo78Y8YVDqMeta || {},
    alias: indexLo78Y8YVDqMeta?.alias || [],
    redirect: indexLo78Y8YVDqMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/forbidden/index.vue").then(m => m.default || m)
  },
  {
    name: indexA06le4Q0SLMeta?.name ?? "index",
    path: indexA06le4Q0SLMeta?.path ?? "/",
    meta: indexA06le4Q0SLMeta || {},
    alias: indexA06le4Q0SLMeta?.alias || [],
    redirect: indexA06le4Q0SLMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlSYE91ATIfMeta?.name ?? "logout",
    path: indexlSYE91ATIfMeta?.path ?? "/logout",
    meta: indexlSYE91ATIfMeta || {},
    alias: indexlSYE91ATIfMeta?.alias || [],
    redirect: indexlSYE91ATIfMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nurie-spa-client/pages/logout/index.vue").then(m => m.default || m)
  }
]