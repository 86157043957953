import revive_payload_client_4sVQNw7RlN from "/home/tsukamoto/public_html/workspace/nurie-spa-client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/tsukamoto/public_html/workspace/nurie-spa-client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/tsukamoto/public_html/workspace/nurie-spa-client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/home/tsukamoto/public_html/workspace/nurie-spa-client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/tsukamoto/public_html/workspace/nurie-spa-client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/home/tsukamoto/public_html/workspace/nurie-spa-client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes
]